import cookieService from 'services/cookie-service'

function handleRefererCode(): void {
  const params = new URLSearchParams(window.location.search)
  const refererCode = params.get('refererCode')
  if (refererCode) {
    cookieService.setItem('refererCode', refererCode, 'Lax', 90)
  }
}

const setupCookies = () => {
  handleRefererCode()
}

setupCookies()
